import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import Loading from '../../../components/Loading'
import LineLogin from '../../../assets/javascript/lineLogin'
import busCodeData from '../../../assets/document/busCode.json'

//from
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import moment from 'moment';
const ModelSupervisor = () => {
    let history = useHistory();
    const token = document.cookie.replace(/(?:(?:^|.*;\s*)lineIdToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    if (token === '' || token === null || token === undefined) {
        localStorage.setItem('page', '/modelSupervisor');
        window.location.href = '/login'
    }

    useEffect(() => {
        LineLogin('/modelSupervisor')
        let API = `${process.env.REACT_APP_GOLDEN_API5000}CheckState`
        axios.get(API, {
            withCredentials: true,
            headers: { 'Access-Control-Allow-Credentials': 'true' }
        })
            .then((res) => {
                setTimeout(() => {
                    if (res.data.ResponseCode === '-1') {
                        localStorage.setItem('page', '/modelSupervisor');
                        window.location.href = '/login'
                        return
                    }
                }, Number(process.env.REACT_APP_TIMEOUT))
            })
            .catch((err) => {
                console.log(err)
                // alert('請重新整理')
            })
    }, []);

    //取得資訊
    const infoToken = document.cookie.replace(/(?:(?:^|.*;\s*)userDataToken\s*=\s*([^;]*).*$)|^.*$/, '$1');
    let name = JSON.parse(unescape(infoToken)).Name,
        busCode = JSON.parse(unescape(infoToken)).BusCode,
        lineId = JSON.parse(unescape(infoToken)).LineId

    //問項
    const questionList = [
        {
            option: '誠信正直',
            title: '我們講真話、不誇大，在我們團隊中做事不需逢迎拍馬；我們勇於承擔且說到做到，一旦承諾使命必達；我們用人首重品格，行為處事總以高標準要求自己，絕不容忍欺騙、徇私圖利、違反法規等行為。',
            desList: [
                '信守承諾但不輕易允諾。',
                '遇事以合規流程，秉公處理。',
                '對人保持中立，不徇私舞弊，勇於揭露。',
                '面對壓力堅守法令規範，樂於解決問題，勇於承擔責任。',
                '不散佈、不揭露公司內部非公開資訊。'
            ],
            Q1: {
                title: '在【誠信正直】價值觀之表現?',
                option: ['推薦，符合「誠信正直」價值觀。', '無意見。']
            },
            Q1Desc: {
                title: '在【誠信正直】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '熱情積極',
            title: '我們以誠待人、關懷他人、樂於溝通，對於夥伴的需求總會不吝伸出援手，創造理解互信的人際關係；我們主動積極、保持成長性思維，總能在團隊中發揮正向影響力。',
            desList: [
                '主動幫助他人解決問題，樂於支持夥伴成長。',
                '主動參與、積極溝通、樂於分享工作經驗。',
                '保持正向思維，傳遞正能量，營造良好工作氛圍。',
                '樂於學習、持續成長與突破，具高度自我驅動力。',
                '對工作品質有高要求，積極投入追求最佳成果。'
            ],
            Q2: {
                title: '在【熱情積極】價值觀之表現?',
                option: ['推薦，符合「熱情積極」價值觀。', '無意見。']
            },
            Q2Desc: {
                title: '在【熱情積極】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '創新卓越',
            title: '我們常保好奇心，願意容忍失敗，鼓勵嘗試用新的方法，更有效益的完成任務，或達成更好的結果；我們懷抱夢想、勇於挑戰，持續追求更遠大的成就',
            desList: [
                '能用新方法，優化現有工作模式，提升效能。',
                '保持好奇心，勇於嘗試，打破固有思維。',
                '善用新科技、新方法，精準解決問題。'
            ],
            Q3: {
                title: '在【創新卓越】價值觀之表現?',
                option: ['推薦，符合「創新卓越」價值觀。', '無意見。']
            },
            Q3Desc: {
                title: '在【創新卓越】價值觀表現之相關描述說明：'
            }
        },
        {
            option: '共享共好',
            title: '我們認同雙贏思維，重視合作精神，在人與人的互動中，不斷尋求互利共好；我們樂於分享成功經驗、幫助其他夥伴一起成長，共創更美好的未來。',
            desList: [
                '主動分享成功經驗，共享學習資源。',
                '關注夥伴發展，幫助團隊成員解決問題。',
                '樂於支持夥伴成長，共同達成更高成就。',
                '重視團隊合作，把總體利益置於個人利益之上。'
            ],
            Q4: {
                title: '在【共享共好】價值觀之表現?',
                option: ['推薦，符合「共享共好」價值觀。', '無意見。']
            },
            Q4Desc: {
                title: '在【共享共好】價值觀表現之相關描述說明：'
            }
        }
    ]

    //主管 LIST
    const modelSupervisorList = [ //如果區副總兼主管 areaSupervisor 留空 arr
        {
            area: '業務北一區部', //區部
            dept: {
                busCode: 'FU06', //單位碼
                busCenter: '台北業務中心', //業務中心
                sectionSupervisor: ['林人杰 業務副總'], //單位主管
                areaSupervisor: [] //區部主管
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA5',
                busCenter: '北五業務中心',
                sectionSupervisor: ['陳精一 業務協理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA6',
                busCenter: '中山業務中心',
                sectionSupervisor: ['蔡欣燕 體系副總'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'FU12',
                busCenter: '花蓮業務中心',
                sectionSupervisor: ['翁淑瑤 業務行政副理'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOF1',
                busCenter: '板橋業務中心',
                sectionSupervisor: ['陳韋婷 資深體系總監'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA3',
                busCenter: '北三業務中心',
                sectionSupervisor: ['余立文 資深體系總監'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北一區部',
            dept: {
                busCode: 'GOA7',
                busCenter: '松江業務中心',
                sectionSupervisor: ['陳之凡 資深體系總監'],
                areaSupervisor: ['林人杰 業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'GOA2',
                busCenter: '北二業務中心',
                sectionSupervisor: ['林美伶 業務行政經理'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU05',
                busCenter: '中壢業務中心',
                sectionSupervisor: ['胡峻業 業務總監'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU15',
                busCenter: '桃園業務中心',
                sectionSupervisor: ['簡大鈞 業務總監'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU21',
                busCenter: '新竹業務中心',
                sectionSupervisor: ['張昌榮 資深體系總監'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'FU10',
                busCenter: '羅東業務中心',
                sectionSupervisor: ['黃紋鈴 業務行政經理'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務北二區部',
            dept: {
                busCode: 'GOG2',
                busCenter: '宜蘭業務中心',
                sectionSupervisor: ['黃紋鈴 業務行政經理'],
                areaSupervisor: ['孫羚沄 資深業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU03',
                busCenter: '台中業務中心',
                sectionSupervisor: ['汪峻佑 業務副總'],
                areaSupervisor: []
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'GOB3',
                busCenter: '鼎盛業務中心',
                sectionSupervisor: ['余立文 資深體系總監'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'GOB2',
                busCenter: '市政業務中心',
                sectionSupervisor: ['鄭雅馨 體系副總'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU08',
                busCenter: '嘉義業務中心',
                sectionSupervisor: ['江盈昇 資深體系總監'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務中區部',
            dept: {
                busCode: 'FU3-5',
                busCenter: '彰化業務中心',
                sectionSupervisor: ['陳肇煌 體系總監'],
                areaSupervisor: ['汪峻佑 業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU02',
                busCenter: '台南業務中心',
                sectionSupervisor: ['陳雅容 業務行政經理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU2-3',
                busCenter: '新營業務中心',
                sectionSupervisor: ['盧秉華 業務行政經理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOD3',
                busCenter: '安平業務中心',
                sectionSupervisor: ['鍾宜臻 業務行政經理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU01',
                busCenter: '高雄業務中心',
                sectionSupervisor: ['陳德成 資深業務副總'],
                areaSupervisor: []
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOE2',
                busCenter: '高二業務中心',
                sectionSupervisor: ['潘柔君 體系協理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOE5',
                busCenter: '北高業務中心',
                sectionSupervisor: ['邱鼎承 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GOE7',
                busCenter: '林園業務中心',
                sectionSupervisor: ['陳德成 資深業務副總'],
                areaSupervisor: []
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU07',
                busCenter: '屏東業務中心',
                sectionSupervisor: ['張簡欽全 業務行政經理'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'FU',
                busCenter: '北高業務中心',
                sectionSupervisor: ['邱鼎承 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        },
        {
            area: '業務南區部',
            dept: {
                busCode: 'GO',
                busCenter: '北高業務中心',
                sectionSupervisor: ['邱鼎承 業務總監'],
                areaSupervisor: ['陳德成 資深業務副總']
            }
        }
    ]

    let [isLoading, setIsLoading] = useState(false),
        [data, setData] = useState({ center: '', dept: [], area: [] }),
        [isCenter, setIsCenter] = useState(null), //隸屬的業務中心
        [centerName, setCenterName] = useState(null), //隸屬業務中心(縮寫)
        [stepPage, setStepPage] = useState('one'), //one:第一頁 ; two:第二頁
        [isOpen, setIsOpen] = useState(true) // 是否打開問卷

    //@ API
    const handleAPI = {
        modelQuestion: function (postData) {
            setIsLoading(true)
            let API = `${process.env.REACT_APP_GOLDEN_BACK_API}MessagePush/ModelQuestion`
            axios.post(API, postData)
                .then((res) => {
                    let { ResponseCode } = res.data
                    if (ResponseCode == '0') {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '0' }
                        })
                        setIsLoading(false)

                    } else if (ResponseCode == '-98') {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '-98' }
                        })
                        setIsLoading(false)
                    } else {
                        history.push({
                            pathname: '/modelSupervisorState',
                            state: { code: '-100' }
                        })
                        setIsLoading(false)
                    }
                })
                .catch((err) => {
                    console.log(err)
                    history.push({
                        pathname: '/modelSupervisorState',
                        state: { code: '-100' }
                    })
                    setIsLoading(false)
                })
        }

    }
    //@ EVENT
    const handleEvent = {
        getCenter: function () {
            modelSupervisorList.map((item, index) => {
                if (busCode == item.dept.busCode) {
                    setIsCenter(item)
                    setData({
                        center: item.dept.busCenter,
                        dept: item.dept.sectionSupervisor,
                        area: item.dept.areaSupervisor
                    })
                }
            })

            busCodeData.map((item) => {
                if (busCode == item.busCode) {
                    setCenterName(item.name)
                } else if (busCode == 'GO') {
                    setCenterName('總公司')
                }
            })
        },
        checkQuestType: function (busCode) { //確認問卷類型
            // S:特別(區副總兼任主管) ; D:標準
            switch (busCode) {
                case 'FU06':
                case 'FU03':
                case 'FU01':
                case 'GOE7':
                    return ('S')
                default: return ('D')
            }
        }
    }

    //@ FORM
    const validationSchema = Yup.object().shape({
        deptQ1: Yup.string()
            .required('必填'),
        deptQ2: Yup.string()
            .required('必填'),
        deptQ3: Yup.string()
            .required('必填'),
        deptQ4: Yup.string()
            .required('必填'),
        areaQ1: Yup.string()
            .required('必填'),
        areaQ2: Yup.string()
            .required('必填'),
        areaQ3: Yup.string()
            .required('必填'),
        areaQ4: Yup.string()
            .required('必填')
    }); //問卷類別為：標準
    const validationSchemaModalIsOne = Yup.object().shape({
        deptQ1: Yup.string()
            .required('必填'),
        deptQ2: Yup.string()
            .required('必填'),
        deptQ3: Yup.string()
            .required('必填'),
        deptQ4: Yup.string()
            .required('必填')
    }); //問卷類別為 : 區副總兼任主管
    const formOptions = { resolver: yupResolver(handleEvent.checkQuestType(busCode) === 'S' ? validationSchemaModalIsOne : validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (val) => {
        val.LineId = lineId
        val.SalesName = name
        val.dept = centerName

        handleAPI.modelQuestion(val)
    }

    //@ STYLE
    let underLineStyle = {
        textDecoration: 'underline #fec842 double 2px'
    }
    useEffect(() => { handleEvent.getCenter() }, [])
    useEffect(() => {
        let now = moment().valueOf()
        let end = moment('2024/12/11 15:00:00').valueOf()
        if (now > end) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [])
    return (
        <>
            <Loading isLoading={isLoading}></Loading>
            {

            }
            <div className={`${isCenter == null ? 'vh-100' : 'h-100'} questionnaire`} style={{ background: 'linear-gradient(90deg, #2FABF4, #1671bc)' }}>
                <div className='container'>
                    <div className={`row`}>
                        {
                            isOpen ? <>
                                <div className='col-12'>
                                    <h2 className='text-center text-center title py-3 fs-3'>{moment().format('yyyy')}年<br /> 業務單位典範主管推薦（{centerName}）</h2>
                                    {
                                        stepPage == 'one' &&
                                        <>
                                            <div className='py-3 px-2 bg-light rounded mb-5'>
                                                <div className='text-question-blue fw-bolder px-1'>
                                                    <p>歡迎參與 {moment().format('yyyy')} 年業務單位典範主管推薦調查。</p>
                                                    <br />
                                                    <p>公勝四大價值觀【誠信正直】、【熱情積極】、【創新卓越】、【共享共好】，是我們團隊最認可的行為準則，也是吸引與晉用人才的關鍵標準。
                                                        您認為自身業務中心及區部主管在四大價值觀的表現如何？您是否給予推薦？
                                                        推薦的同時，也歡迎回饋我們推薦的理由及相關說明！
                                                    </p>
                                                </div>
                                                <hr />
                                                {
                                                    isCenter !== null ?
                                                        <ul className='fw-bolder' style={{ lineHeight: '35px' }}>
                                                            <li>調查對象：{data.center}同仁</li>
                                                            {
                                                                handleEvent.checkQuestType(busCode) === 'S' ?
                                                                    <li>推薦對象（單位&區部）：{data.dept}</li>
                                                                    :
                                                                    <>
                                                                        {
                                                                            data.dept.map((item, index) => {
                                                                                return (
                                                                                    <li>推薦對象（單位）：{item}</li>
                                                                                )
                                                                            })
                                                                        }
                                                                        <li>推薦對象（區部）：{data.area}</li>
                                                                    </>
                                                            }
                                                        </ul> : '無調查對象'
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    isCenter !== null &&
                                    <>
                                        <div className='w-100 mb-5' style={{ borderBottom: '1.5px solid #fff' }}></div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            {/* 單位主管 */}
                                            <div className={'d-block'}>
                                                {
                                                    data.dept.map((item, index) => {
                                                        return (
                                                            <p className='text-light my-2 fw-bolder ps-2' style={{ borderLeft: '2px solid #fff', fontSize: '20px' }}>
                                                                單位主管 -
                                                                <span className='mx-1'>
                                                                    {item}
                                                                </span>
                                                            </p>
                                                        )
                                                    })
                                                }

                                                <p className='py-2 text-light fw-bolder'>依您對自身<span style={underLineStyle}>{handleEvent.checkQuestType(busCode) === 'S' ? '單位&區部主管' : '業務中心主管'}</span>的了解，煩請參閱下列價值觀的相關說明，並回答相關問題，謝謝。</p>
                                                {
                                                    questionList.map((item, index) => {
                                                        return (
                                                            <div className='col-12 my-3'>
                                                                <div className='bg-light rounded p-3'>
                                                                    <div className='row align-items-center question-box mb-3'>
                                                                        <span className='col-2 number-box bg-golden-yellow'>Q{index + 1}</span>
                                                                        <span className='col-10 fw-bolder'>
                                                                            <span style={{ fontSize: '18px' }}>價值觀【{item.option}】：</span><br />
                                                                            {item.title}
                                                                        </span>
                                                                    </div>
                                                                    <ul className='mb-3 text-question-blue'>
                                                                        {item.desList.map((kitem, kindex) => {
                                                                            return (
                                                                                <li>{kitem}</li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                    <hr />
                                                                    <div className='ms-2'>
                                                                        <div className='answer-box'>
                                                                            <p className='fw-bolder mb-2'>您是否推薦<span style={underLineStyle}>{handleEvent.checkQuestType(busCode) === 'S' ? '單位&區部主管' : '單位主管'}</span>{item[`Q${index + 1}`].title}<span className='text-danger mx-1'>*</span></p>
                                                                            <div className="invalid-feedback d-block fw-bolder">{errors[`deptQ${index + 1}`] && '*必填'}</div>
                                                                            {
                                                                                data.dept.length >= 2 ?
                                                                                    <>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-1`} value='Y1' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-1`}>推薦，{data.dept[0]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-2`} value='Y2' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-2`}>推薦，{data.dept[1]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-3`} value='Y3' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-3`}>推薦，{data.dept[0]}&{data.dept[1]}</label>
                                                                                        </div>
                                                                                        <div className='form-check mb-2'>
                                                                                            <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-4`} value='N' {...register(`deptQ${index + 1}`)} />
                                                                                            <label className='form-check-label' htmlFor={`Q${index + 1}-4`}>無意見</label>
                                                                                        </div>
                                                                                        {
                                                                                            data.dept.map((kitem, kindex) => {
                                                                                                return (
                                                                                                    <div className='mt-3'>
                                                                                                        <p className='fw-bolder mb-2'><span style={underLineStyle}>{handleEvent.checkQuestType(busCode) === 'S' ? '單位&區部主管' : '單位主管'}</span> - {kitem} 在【{item.option}】價值觀表現之相關描述說明：</p>
                                                                                                        <div className='answer-box fw-bolder'>
                                                                                                            <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc${kindex + 1}`)} placeholder='請描述說明'></textarea>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            item[`Q${index + 1}`].option.map((aitem, aindex) => {
                                                                                                return (
                                                                                                    <div className='form-check mb-2'>
                                                                                                        <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`Q${index + 1}-${aindex + 1}`} value={`${aindex == 0 ? 'Y1' : 'N'}`} {...register(`deptQ${index + 1}`)} />
                                                                                                        <label className='form-check-label' htmlFor={`Q${index + 1}-${aindex + 1}`}>{aitem}</label>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        <div className='mt-3'>
                                                                                            <div>
                                                                                                <p className='fw-bolder mb-2'><span style={underLineStyle}>{handleEvent.checkQuestType(busCode) === 'S' ? '單位&區部主管' : '單位主管'}</span>{item[`Q${index + 1}Desc`].title}</p>
                                                                                                <div className='answer-box fw-bolder'>
                                                                                                    <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc1`)} placeholder='請描述說明'></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-none'>
                                                                                                <textarea className='form-control' cols="50" rows="5" {...register(`deptQ${index + 1}Desc2`)}></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {/* 區部主管 */}
                                            {
                                                data.area.length >= 1 &&
                                                <div>
                                                    <div className='w-100 my-5' style={{ borderBottom: '1.5px solid #fff' }}></div>
                                                    <p className='text-light my-2 fw-bolder ps-2' style={{ borderLeft: '2px solid #fff', fontSize: '20px' }}>
                                                        區部主管 -
                                                        <span className='mx-1'>
                                                            {
                                                                data.area.map((item, index) => {
                                                                    return item
                                                                })
                                                            }
                                                        </span>
                                                    </p>
                                                    <p className='py-2 text-light fw-bolder'>依您對自身<span style={underLineStyle}>區部主管</span>的了解，煩請參閱下列價值觀的相關說明，並回答相關問題，謝謝。</p>
                                                    {
                                                        questionList.map((item, index) => {
                                                            return (
                                                                <div className='col-12 mb-3'>
                                                                    <div className='bg-light rounded p-3'>
                                                                        <div className='row align-items-center question-box mb-3'>
                                                                            <span className='col-2 number-box bg-golden-yellow'>Q{index + 1 + 4}</span>
                                                                            <span className='col-10 fw-bolder'>
                                                                                <span style={{ fontSize: '18px' }}>價值觀【{item.option}】：</span><br />
                                                                                {item.title}
                                                                            </span>
                                                                        </div>
                                                                        <ul className='mb-3 text-question-blue'>
                                                                            {item.desList.map((kitem, kindex) => {
                                                                                return (
                                                                                    <li>{kitem}</li>
                                                                                )
                                                                            })}
                                                                        </ul>
                                                                        <hr />
                                                                        <div className='ms-3'>
                                                                            <div className='answer-box' >
                                                                                <p className='fw-bolder mb-2'>您是否推薦<span style={underLineStyle}>區部主管</span>{item[`Q${index + 1}`].title}<span className='text-danger mx-1'>*</span></p>
                                                                                <div className="invalid-feedback d-block fw-bolder">{errors[`areaQ${index + 1}`] && '*必填'}</div>
                                                                                {
                                                                                    item[`Q${index + 1}`].option.map((aitem, aindex) => {
                                                                                        return (
                                                                                            <div className='form-check mb-2'>
                                                                                                <input className='form-check-input' type='radio' name={`Q${index + 1}`} id={`areaQ${index + 1}-${aindex + 1}`} value={aindex == 0 ? 'Y1' : 'N'} {...register(`areaQ${index + 1}`)} />
                                                                                                <label className='form-check-label' htmlFor={`areaQ${index + 1}-${aindex + 1}`}>{aitem}</label>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                <div className='mt-4'>
                                                                                    <p className='fw-bolder mb-2'><span style={underLineStyle}>區部主管</span>{item[`Q${index + 1}Desc`].title}</p>
                                                                                    <div className='answer-box fw-bolder'>
                                                                                        <textarea className='form-control' cols="50" rows="5" {...register(`areaQ${index + 1}Desc`)}></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                            <div className='text-center py-3'>
                                                <button type='submit' className='btn btn-light fw-bolder'>送出</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </> :
                                <div className="col-12">
                                    <div className='vh-100'><p className='fs-5 text-center text-light my-5 fw-bolder'>問卷已結束</p></div>
                                </div>
                        }
                    </div>
                </div>
            </div >
        </>
    )
}
//answerList
export default ModelSupervisor